
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ContactChannelOption } from '@govflanders/mbp-admin-panel-shared';
import { v4 as uuid } from 'uuid';

@Component
export default class ChannelTile extends Vue {
  @Prop()
  public value: ContactChannelOption;

  private id = uuid();

  public openTileModal() {
    this.$root.$emit('modal-toggle', this.modalId);
  }

  public get modalId(): string {
    return this.id + 'modal';
  }

  public get currentValue(): ContactChannelOption {
    return this.value;
  }

  public set currentValue(value: ContactChannelOption) {
    this.$emit('input', value);
  }

  public handleRemoveContactOption() {
    this.$emit('removeChannel');
  }
}

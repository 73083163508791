
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Contact } from '@govflanders/mbp-admin-panel-shared';

@Component({})
export default class ContactoptionsSocials extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  public value: Contact;

  public socials = [
    {
      icon: 'x',
      text: `${this.$t('contacts.form.x')}`,
      description: `${this.$t('contacts.form.followOn')} ${this.$t('contacts.form.x')}`,
      url: '',
    },
    {
      icon: 'facebook',
      text: `${this.$t('contacts.form.facebook')}`,
      description: `${this.$t('contacts.form.followOn')} ${this.$t('contacts.form.facebook')}`,
      url: '',
    },
    {
      icon: 'linkedin',
      text: `${this.$t('contacts.form.linkedin')}`,
      description: `${this.$t('contacts.form.followOn')} ${this.$t('contacts.form.linkedin')}`,
      url: '',
    },
    {
      icon: 'instagram',
      text: `${this.$t('contacts.form.instagram')}`,
      description: `${this.$t('contacts.form.followOn')} ${this.$t('contacts.form.instagram')}`,
      url: '',
    },
    {
      icon: 'youtube',
      text: `${this.$t('contacts.form.youtube')}`,
      description: `${this.$t('contacts.form.followOn')} ${this.$t('contacts.form.youtube')}`,
      url: '',
    },
  ];

  public created() {
    this.socials.forEach(el => {
      const item = this.value.social.media?.find(i => i.text === el.text);
      if (item && item.url !== '') {
        el.url = item.url;
      }
    });
  }

  public handleInputSocialUrl(): void {
    const medialist = this.socials.filter(el => el.url !== '');
    this.value.social =
      medialist && medialist.length ? { description: 'Blijf op de hoogte', media: medialist } : {};

    this.handleChange(this.value);
  }

  public handleChange(value): void {
    this.$emit('input', value);
  }
}

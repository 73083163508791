
import _ from 'lodash';
import { Component, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Anchor from '@/components/shared/Anchor.vue';
import PageHeader from '@/components/shared/PageHeader.vue';
import PageHeaderNavigationAction from '@/components/shared/PageHeaderNavigationAction.vue';
import SearchPageTemplate from '@/components/search-page/SearchPageTemplate.vue';
import {
  SearchFilterDefinitions,
  SearchFilterTypes,
  SearchPageMetaData,
  SearchQueryData,
} from '@/utility/definitions';
import { Contact } from '@govflanders/mbp-admin-panel-shared';
import RoleGuard from '@/utility/auth/RoleGuard.vue';
import { mixins } from 'vue-class-component';

@Component({
  components: {
    PageHeader,
    PageHeaderNavigationAction,
    Anchor,
    SearchPageTemplate,
  },
})
export default class ContactSearch extends mixins(RoleGuard) {
  @Getter('contactSearch/isFailed')
  private isFailed!: boolean;
  @Getter('contactSearch/isLoading')
  private isLoading!: boolean;
  @Getter('contactSearch/isSuccess')
  private isSuccess!: boolean;
  @Getter('contactSearch/results')
  private results: Contact[] | null;
  @Getter('contactSearch/pageMetaData')
  private pageMetaData!: SearchPageMetaData | null;

  private offset = 0;

  mounted() {
    // check if route has offset on pageload (if so set offset var)
    if (this.$route.query.offset) {
      this.offset = parseInt(`${this.$route.query.offset}`);
    }
  }

  /**
   * Internal storage for our search query.
   *
   * @var {SearchQueryData}
   */
  private queryObject: SearchQueryData = {};

  /**
   * Get the search query.
   *
   * @return {SearchQueryData}
   *   An object which holds the search query.
   */
  public get query(): SearchQueryData {
    return this.queryObject;
  }

  /**
   * Update the current search query.
   *
   * @param {SearchQueryData} value
   *   Value to be used as search query.
   */
  public set query(value: SearchQueryData) {
    // Update our internal copy of the query.
    this.queryObject = _(value)
      .omitBy(_.isUndefined)
      .omitBy(_.isNull)
      .value();
    // Update the router to include the updated query params.
    this.$router.push({ name: 'contacts', query: this.queryObject });
  }

  /**
   * Execute search operation.
   *
   * @return {Promise<void>}
   *   A promise to perform a search operation.
   */
  public async search(): Promise<void> {
    // Dispatch search operationf for given query.
    await this.$store.dispatch('contactSearch/search', this.query);
  }

  /**
   * Get filters for page.
   *
   * @return {SearchFilterDefinitions}
   *   The list of filter configs
   */
  public get filters(): SearchFilterDefinitions {
    return [
      {
        type: SearchFilterTypes.Input,
        name: 'query',
        label: 'common.search.filters.query.label',
      },
    ];
  }

  /**
   * Watch for changes to the query object so we can refresh the search results.
   */
  @Watch('queryObject')
  private onQueryChange() {
    // Perform search operation as query has changed.
    this.search();
  }

  /**
   * React to router navigation being performed to keep search query up to date.
   */
  private beforeRouteEnter(to, from, next) {
    // Allow navigation but await success before updating the
    // query params.
    next((vm: this) => {
      // Update the query params to match route query params.
      vm.queryObject = _(to.query)
        .omitBy(_.isUndefined)
        .omitBy(_.isNull)
        .value();
    });
  }

  /**
   * React to router navigation being performed to keep search query up to date.
   */
  private beforeRouteUpdate(to, from, next) {
    // Update the query params to match route query params.
    this.queryObject = _(to.query)
      .omitBy(_.isUndefined)
      .omitBy(_.isNull)
      .value();
    // Allow route to be updated.
    next();
  }
}

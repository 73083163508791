
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ChannelTileBody extends Vue {
  @Prop()
  public tileType: string;
  @Prop()
  public modalId: string;

  public handleRemoveContactOption(): void {
    this.$emit('delete');
  }
}


import { Component, Prop, Vue } from 'vue-property-decorator';
import { ContactChannelOption } from '@govflanders/mbp-admin-panel-shared';
import ContactoptionsChannelAvailability from '@/components/contacts/channels/availability/ContactoptionsChannelAvailability.vue';

@Component({
  components: {
    ContactoptionsChannelAvailability,
  },
})
export default class LocationChannelForm extends Vue {
  @Prop()
  public value: ContactChannelOption;

  public get currentValue(): ContactChannelOption {
    return this.value;
  }

  public set currentValue(value: ContactChannelOption) {
    this.$emit('input', value);
  }
}


import { Component, Prop, Vue } from 'vue-property-decorator';
import { ContactOptionAvailabilityException } from '@govflanders/mbp-admin-panel-shared';
import ContactOptionsTimeSlotRow from '@/components/contacts/ContactOptionsTimeSlotRow.vue';

@Component({
  components: { ContactOptionsTimeSlotRow },
})
export default class ContactoptionsChannelAvailabilityExceptions extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  public value: ContactOptionAvailabilityException;

  public get currentValue(): ContactOptionAvailabilityException {
    return this.value;
  }

  public set currentValue(value: ContactOptionAvailabilityException) {
    this.$emit('input', value);
  }

  public get exceptionDatePickerValue(): Date[] {
    return this.value?.date ? [new Date(this.value.date)] : null;
  }

  public set exceptionDatePickerValue(dates: Date[]) {
    const formatDate = date => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    this.value.date = dates && dates.length && dates[0] ? formatDate(dates[0]) : null;
  }

  public handleHourDelete(hourIndex: number): void {
    this.currentValue.hours.splice(hourIndex, 1);
  }

  public addHour(): void {
    // check if previous timeslot is filled in
    if (this.currentValue.hours) {
      const lastTimeslot = this.currentValue.hours[this.currentValue.hours.length - 1];
      if (lastTimeslot && (!lastTimeslot.from || !lastTimeslot.to)) {
        return;
      }
    }

    this.currentValue.hours.push({ from: null, to: null, remark: null });
  }

  public deleteException(): void {
    this.$emit('delete');
  }
}

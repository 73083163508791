
import { Component, Prop, Vue } from 'vue-property-decorator';
import JsonEditor from '@/components/shared/forms/JsonEditor.vue';
import { getContactSchema, getPrivacyTemplate } from '@/utility/contacts';
import { Contact } from '@govflanders/mbp-admin-panel-shared';
import UuidField from '@/components/shared/forms/UuidField.vue';
import ContactoptionsChannels from '@/components/contacts/channels/ContactoptionsChannels.vue';
import ContactoptionsSocials from '@/components/contacts/ContactoptionsSocials.vue';
import { v4 as uuidv4 } from 'uuid';
import { Getter } from 'vuex-class';
import { debounce } from 'lodash';

@Component({
  components: {
    UuidField,
    JsonEditor,
    ContactoptionsChannels,
    ContactoptionsSocials,
  },
})
export default class ContactForm extends Vue {
  public schema: object;

  @Prop({
    type: Object,
    required: false,
  })
  public value: Contact;

  @Getter('organisationSearch/isLoading')
  public organisationNamesLoading: boolean;

  @Getter('organisationSearch/results')
  public organisationNames: string[];

  public loadOrganisationNames = debounce(
    query => this.$store.dispatch('organisationSearch/search', { query }),
    300,
  );

  public created() {
    this.schema = getContactSchema();

    if (!this.currentValue.key) {
      this.currentValue.key = uuidv4();
    }
  }

  public get privacyCheckbox() {
    return !!this.currentValue.privacy;
  }

  public set privacyCheckbox(value: boolean) {
    if (value) {
      this.currentValue = { ...this.currentValue, privacy: getPrivacyTemplate() };
    } else {
      this.currentValue = { ...this.currentValue, privacy: null };
    }
  }

  public handleInputConfigName(value: string): void {
    this.currentValue.about = value;
  }

  public get currentValue(): Contact {
    return this.value;
  }

  public set currentValue(value: Contact) {
    this.$emit('input', value);
  }
}


import { Component, Prop, Vue } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';
import ContactOptionsTimeSlotRow from '@/components/contacts/ContactOptionsTimeSlotRow.vue';
import { ContactOptionTimeSlot } from '@govflanders/mbp-admin-panel-shared';
import OpeninghoursRemark from '@/components/contacts/channels/availability/OpeninghoursRemark.vue';

@Component({
  components: { OpeninghoursRemark, ContactOptionsTimeSlotRow },
})
export default class ContactoptionsChannelAvailabilityHours extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  public value: ContactOptionTimeSlot[][];

  public get currentValue(): ContactOptionTimeSlot[][] {
    return this.value || new Array(7).fill([]);
  }

  public set currentValue(value: ContactOptionTimeSlot[][]) {
    this.$emit('input', value);
  }

  public days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

  public handleRemoveTimeslotElement(dayIndex, slotIndex) {
    const updatedValue = cloneDeep(this.currentValue);
    updatedValue[dayIndex] = updatedValue[dayIndex].filter((_, index) => index !== slotIndex);

    this.currentValue = updatedValue;
  }

  public handleAddTimeslotElement(dayIndex) {
    const updatedValue = cloneDeep(this.currentValue);

    // check if previous timeslot is filled in
    if (updatedValue[dayIndex].length > 0) {
      const lastTimeslot = updatedValue[dayIndex][updatedValue[dayIndex].length - 1];
      if (!lastTimeslot.from || !lastTimeslot.to) {
        return;
      }
    }

    updatedValue[dayIndex].push({ from: null, to: null, remark: null });

    this.currentValue = updatedValue;
  }

  public handleChange() {
    this.$emit('input', this.currentValue);
  }
}

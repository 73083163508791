
import {Component, Prop, Vue} from 'vue-property-decorator';
import {
  ContactOptionTimeSlot,
  ContactOptionTimeSlotRemark,
} from '@govflanders/mbp-admin-panel-shared';
import OpeninghoursRemark from '@/components/contacts/channels/availability/OpeninghoursRemark.vue';

@Component({
  components: {
    OpeninghoursRemark,
  },
})
export default class OpeninghoursTileSummary extends Vue {
  @Prop()
  public value: ContactOptionTimeSlot[];

  public isValidTimeslot(slot: ContactOptionTimeSlot) {
    return this.isValidFromTo(slot) && this.isValidRemark(slot);
  }

  public isValidFromTo(slot: ContactOptionTimeSlot) {
    return slot && slot.from && slot.to;
  }

  public isValidRemark(slot: ContactOptionTimeSlot): boolean {
    return slot && (slot.remark !== ContactOptionTimeSlotRemark.OTHER || !!slot.customRemark);
  }
}

import { Schema, Templates } from '@/json';
import { Contact, ContactChannelOption, ContactPrivacy } from '@govflanders/mbp-admin-panel-shared';

export function getContactSchema(): object {
  return Schema.Contact;
}

export function getContactTemplate(): Contact {
  return Object.assign({}, Templates.Contact.defaultSet as Contact);
}

export function getContactChannelOptionTemplate(type: string): ContactChannelOption {
  return Object.assign({}, Templates.Contact[type] as ContactChannelOption);
}

export function getPrivacyTemplate(): ContactPrivacy {
  return Object.assign({}, Templates.Contact['privacy']) as ContactPrivacy;
}

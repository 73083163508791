
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ContactOptionAvailabilityException } from '@govflanders/mbp-admin-panel-shared';
import ContactOptionsTimeSlotRow from '@/components/contacts/ContactOptionsTimeSlotRow.vue';
import ContactoptionsChannelAvailabilityExceptionBlock from '@/components/contacts/channels/availability/ContactoptionsChannelAvailabilityExceptionBlock.vue';

@Component({
  components: { ContactoptionsChannelAvailabilityExceptionBlock, ContactOptionsTimeSlotRow },
})
export default class ContactoptionsChannelAvailabilityExceptions extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  public value: ContactOptionAvailabilityException[];

  public get currentValue(): ContactOptionAvailabilityException[] {
    return this.value || [];
  }

  public set currentValue(value: ContactOptionAvailabilityException[]) {
    this.$emit('input', value);
  }

  public addException(): void {
    this.currentValue.push({ date: null, information: null, hours: [] });
  }

  public deleteException(index: number): void {
    this.currentValue.splice(index, 1);
  }
}


import { Component } from 'vue-property-decorator';
import LocationChannelForm from '@/components/contacts/channels/channelForms/LocationChannelForm.vue';
import ChannelTile from './ChannelTile.vue';
import ChannelTileBody from '@/components/contacts/channels/previewTiles/ChannelTileBody.vue';
import OpeninghoursTileBody from '@/components/contacts/channels/previewTiles/OpeninghoursTileBody.vue';

@Component({
  components: {
    LocationChannelForm,
    ChannelTileBody,
    OpeninghoursTileBody,
  },
})
export default class LocationChannelTile extends ChannelTile {
  public tileType = 'location';
}


import {Component, Prop, Vue} from 'vue-property-decorator';
import {
  ContactOptionAvailabilityHoliday,
  ContactOptionAvailabilityHolidayWithHours,
  ContactOptionTimeSlot,
  Holidays
} from '@govflanders/mbp-admin-panel-shared';
import ContactoptionsChannelAvailabilityExceptionBlock
  from '@/components/contacts/channels/availability/ContactoptionsChannelAvailabilityExceptionBlock.vue';
import ContactoptionsChannelAvailabilityHolidayBlock
  from "@/components/contacts/channels/availability/ContactoptionsChannelAvailabilityHolidayBlock.vue";

@Component({
  components: {
    ContactoptionsChannelAvailabilityHolidayBlock,
    ContactoptionsChannelAvailabilityExceptionBlock,
  },
})
export default class ContactoptionsChannelAvailabilityHolidays extends Vue {
  @Prop({
    type: Array,
  })
  public value: (ContactOptionAvailabilityHoliday | ContactOptionAvailabilityHolidayWithHours)[] | undefined;

  public options: ContactOptionAvailabilityHoliday[] = [...Holidays];

  public get currentValue(): ContactOptionAvailabilityHolidayWithHours[] {
    if (this.value?.length && typeof this.value[0] === 'string') {
      return this.value.map(el => ({name: el, hours: []} as ContactOptionAvailabilityHolidayWithHours));
    }

    return (this.value || []) as ContactOptionAvailabilityHolidayWithHours[];
  }

  public set currentValue(value: ContactOptionAvailabilityHolidayWithHours[]) {
    this.$emit('input', value);
  }

  public setHolidayValue(name: ContactOptionAvailabilityHoliday, hours: ContactOptionTimeSlot[]) {
    this.currentValue[this.currentValue.findIndex(el => el.name === name)] = {name, hours};
    this.currentValue = [...this.currentValue];
  }

  public setHoliday(add: boolean, name: ContactOptionAvailabilityHoliday) {
    if (add) {
      this.currentValue = [...this.currentValue, {name, hours: []}];
    } else {
      this.currentValue = this.currentValue.filter(el => el.name !== name);
    }
  }

  public selectAll() {
    this.currentValue = this.options.reduce((acc, curr) => {
      const existing = this.currentValue.find(el => el.name === curr);
      return [...acc, existing ? existing : {name: curr, hours: []}];
    }, []);
  }

  public deselectAll() {
    this.currentValue = [];
  }
}


import { Component } from 'vue-property-decorator';
import LinkChannelForm from '@/components/contacts/channels/channelForms/LinkChannelForm.vue';
import ChannelTile from '@/components/contacts/channels/previewTiles/ChannelTile.vue';
import ChannelTileBody from '@/components/contacts/channels/previewTiles/ChannelTileBody.vue';

@Component({
  components: {
    ChannelTileBody,
    LinkChannelForm,
  },
})
export default class LinkChannelTile extends ChannelTile {
  public tileType = 'link';
}

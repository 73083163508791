
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ContactOptionTimeSlotRemark } from '@govflanders/mbp-admin-panel-shared';

@Component
export default class OpeninghoursRemark extends Vue {
  @Prop()
  public remark: ContactOptionTimeSlotRemark;

  @Prop()
  public customRemark: string;
}


import {Component, Prop, Vue} from 'vue-property-decorator';
import PhoneChannelForm from '@/components/contacts/channels/channelForms/PhoneChannelForm.vue';
import ChannelTileBody from '@/components/contacts/channels/previewTiles/ChannelTileBody.vue';
import {
  ContactChannelOption,
  ContactOptionTimeSlot,
  ContactOptionTimeSlotRemark,
} from '@govflanders/mbp-admin-panel-shared';
import OpeninghoursRemark from '@/components/contacts/channels/availability/OpeninghoursRemark.vue';
import OpeninghoursTileSummary
  from "@/components/contacts/channels/previewTiles/OpeninghoursTileSummary.vue";

@Component({
  components: {
    OpeninghoursTileSummary,
    PhoneChannelForm,
    ChannelTileBody,
    OpeninghoursRemark,
  },
})
export default class OpeninghoursTileBody extends Vue {
  @Prop()
  public value: ContactChannelOption;

  public days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

  public isValidTimeslot(slot: ContactOptionTimeSlot) {
    return this.isValidFromTo(slot) && this.isValidRemark(slot);
  }

  public isValidFromTo(slot: ContactOptionTimeSlot) {
    return slot && slot.from && slot.to;
  }

  public isValidRemark(slot: ContactOptionTimeSlot): boolean {
    return slot && (slot.remark !== ContactOptionTimeSlotRemark.OTHER || !!slot.customRemark);
  }
}

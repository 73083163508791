
import { Component, Prop, Vue } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import { ContactChannelOption } from '@govflanders/mbp-admin-panel-shared';
import { getContactChannelOptionTemplate } from '@/utility/contacts';
import ContactoptionsChannelAvailability from '@/components/contacts/channels/availability/ContactoptionsChannelAvailability.vue';
import LocationChannelTile from '@/components/contacts/channels/previewTiles/LocationChannelTile.vue';
import PhoneChannelTile from '@/components/contacts/channels/previewTiles/PhoneChannelTile.vue';
import LinkChannelTile from '@/components/contacts/channels/previewTiles/LinkChannelTile.vue';
import MailChannelTile from '@/components/contacts/channels/previewTiles/MailChannelTile.vue';
import LinkChannelForm from '@/components/contacts/channels/channelForms/LinkChannelForm.vue';
import MailChannelForm from '@/components/contacts/channels/channelForms/MailChannelForm.vue';
import PhoneChannelForm from '@/components/contacts/channels/channelForms/PhoneChannelForm.vue';
import LocationChannelForm from '@/components/contacts/channels/channelForms/LocationChannelForm.vue';
import ChannelTile from '@/components/contacts/channels/previewTiles/ChannelTile.vue';

@Component({
  components: {
    draggable,
    ContactoptionsChannelAvailability,
    LocationChannelTile,
    PhoneChannelTile,
    LinkChannelTile,
    MailChannelTile,
    LinkChannelForm,
    MailChannelForm,
    PhoneChannelForm,
    LocationChannelForm,
  },
})
export default class ContactoptionsChannels extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  public value: ContactChannelOption[];

  public contactOptions = ['phone', 'location', 'link', 'mail'];

  public getTileComponentData(option: ContactChannelOption) {
    if (option.type === 'link' && option.icon === 'screen') {
      return 'LinkChannelTile';
    } else if (option.type === 'link' && option.icon === 'mail') {
      return 'MailChannelTile';
    } else if (option.type === 'location') {
      return 'LocationChannelTile';
    } else if (option.type === 'link-mobile') {
      return 'PhoneChannelTile';
    }
  }

  public get currentValue(): ContactChannelOption[] {
    return this.value;
  }

  public set currentValue(value: ContactChannelOption[]) {
    this.$emit('input', value);
  }

  public handleAddContactOption(type) {
    const tileIndex = this.currentValue.length;
    this.currentValue = [...this.currentValue, getContactChannelOptionTemplate(type)];

    Vue.nextTick(() => {
      const tileList = this.$refs['channelTile' + tileIndex] as ChannelTile[];

      if (tileList && tileList.length) {
        tileList[0].openTileModal();
      }
    });
  }

  public handleRemoveContactOption(channelIndex): void {
    this.currentValue = this.currentValue.filter((el, i) => i !== channelIndex);
  }
}


import MailChannelForm from '@/components/contacts/channels/channelForms/MailChannelForm.vue';
import ChannelTile from '@/components/contacts/channels/previewTiles/ChannelTile.vue';
import ChannelTileBody from '@/components/contacts/channels/previewTiles/ChannelTileBody.vue';
import { Component } from 'vue-property-decorator';

@Component({
  components: {
    MailChannelForm,
    ChannelTileBody,
  },
})
export default class MailChannelTile extends ChannelTile {
  public tileType = 'mail';
}


import {Component, Prop, Vue} from 'vue-property-decorator';
import {ContactOptionTimeSlot} from '@govflanders/mbp-admin-panel-shared';
import ContactOptionsTimeSlotRow from '@/components/contacts/ContactOptionsTimeSlotRow.vue';

@Component({
  components: {ContactOptionsTimeSlotRow},
})
export default class ContactoptionsChannelAvailabilityHolidays extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  public value: ContactOptionTimeSlot[];

  public get currentValue(): ContactOptionTimeSlot[] {
    return this.value;
  }

  public set currentValue(value: ContactOptionTimeSlot[]) {
    this.$emit('input', value);
  }

  public handleHourDelete(hourIndex: number): void {
    this.currentValue = this.currentValue.filter((el, index) => index !== hourIndex);
  }

  public addHour(): void {
    const lastTimeslot = this.currentValue?.length ? this.currentValue[this.currentValue.length - 1] : undefined;
    if (lastTimeslot && (!lastTimeslot.from || !lastTimeslot.to)) {
      return;
    }

    this.currentValue = [...this.currentValue, {from: null, to: null, remark: null}];
  }
}


import { Component } from 'vue-property-decorator';
import PhoneChannelForm from '@/components/contacts/channels/channelForms/PhoneChannelForm.vue';
import ChannelTileBody from '@/components/contacts/channels/previewTiles/ChannelTileBody.vue';
import ChannelTile from '@/components/contacts/channels/previewTiles/ChannelTile.vue';
import OpeninghoursTileBody from '@/components/contacts/channels/previewTiles/OpeninghoursTileBody.vue';

@Component({
  components: {
    OpeninghoursTileBody,
    PhoneChannelForm,
    ChannelTileBody,
  },
})
export default class PhoneChannelTile extends ChannelTile {
  public tileType = 'phone';
}


import { Component, Prop, Vue } from 'vue-property-decorator';
import { ContactChannelOption } from '@govflanders/mbp-admin-panel-shared';
import { getContactChannelOptionTemplate } from '@/utility/contacts';
import ContactoptionsChannelAvailabilityHours from '@/components/contacts/channels/availability/ContactoptionsChannelAvailabilityHours.vue';
import ContactoptionsChannelAvailabilityExceptions from '@/components/contacts/channels/availability/ContactoptionsChannelAvailabilityExceptions.vue';
import ContactoptionsChannelAvailabilityHolidays from '@/components/contacts/channels/availability/ContactoptionsChannelAvailabilityHolidays.vue';

@Component({
  components: {
    ContactoptionsChannelAvailabilityExceptions,
    ContactoptionsChannelAvailabilityHours,
    ContactoptionsChannelAvailabilityHolidays,
  },
})
export default class ContactoptionsChannelAvailability extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  public value: ContactChannelOption;

  public get currentValue(): ContactChannelOption {
    return this.value;
  }

  public set currentValue(value: ContactChannelOption) {
    this.$emit('input', value);
  }

  public initAvailability() {
    this.currentValue = {
      ...this.currentValue,
      availability: getContactChannelOptionTemplate(this.value.type)?.availability,
    };
  }
}

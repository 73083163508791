
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  ContactOptionTimeSlot,
  ContactOptionTimeSlotRemark,
} from '@govflanders/mbp-admin-panel-shared';

@Component({})
export default class ContactOptionsTimeSlotRow extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  public value: ContactOptionTimeSlot;

  @Prop({
    type: Boolean,
    required: false,
  })
  public withRemarks: boolean;

  public remarkOptions = Object.values(ContactOptionTimeSlotRemark);

  public get from(): string {
    return this.value?.from;
  }

  public set from(value: string) {
    this.$emit('input', { ...this.value, from: value });
  }

  public get to(): string {
    return this.value?.to;
  }

  public set to(value: string) {
    this.$emit('input', { ...this.value, to: value });
  }

  public get isCustomRemark(): boolean {
    return this.value.remark === ContactOptionTimeSlotRemark.OTHER;
  }

  public handleDeletion(): void {
    this.$emit('delete');
  }

  public remarkChanged(): void {
    if (!this.isCustomRemark && this.value.customRemark) {
      this.value.customRemark = '';
    }
  }
}
